<template>
  <form class="container animated zoomIn" @submit.prevent="submitHandler">
    <div class="row shake mt-5 pt-5">
      <div class="col text-center">
        <h1 class="h1 mb-3 mt-5">Авторизация</h1>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-6 col-sm-8 card mx-2 mt-2 py-2 px-2">
        <mdb-input
          type="text"
          id="login"
          label="Логин"
          icon="user"
          class="mb-5"
          v-model="username"
        />
      </div>
      <div class="w-100"></div>
      <div class="col-lg-5 col-md-6 col-sm-8 mx-2 mt-2 py-2 px-2 card">
        <mdb-input
          type="password"
          id="password"
          label="Пароль"
          icon="key"
          class="mb-5"
          v-model="password"
        />
      </div>
      <div class="w-100"></div>
      <div class="col-5 mx-2 mt-2 py-2 px-2 text-center">
        <button id="test" class="btn success-color" type="submit">Войти</button>
      </div>
    </div>
  </form>
</template>

<script>
import {
  mdbContainer,
  mdbBtn,
  mdbIcon,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
  mdbInput,
  mdbModalTitle,
} from "mdbvue";
import { AUTH_REQUEST } from "@/store/actions/auth";
export default {
  name: "login",
  components: {
    mdbContainer,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    mdbModalTitle,
    mdbIcon,
  },
  data: () => ({
    username: "",
    password: "",
    login: false,
  }),
  methods: {
    submitHandler() {
      const { username, password } = this;
      this.$store.dispatch(AUTH_REQUEST, { username, password }).then(() => {
        if (username === "manufacturing_man") {
          this.$router.push("/manufacture");
          return;
        } else if (username === "9055") {
          this.$router.push("/designdep");
          return;
        } else if (username === "admin_man") {
          this.$router.push("/admin");
          return;
        } else {
          this.$router.push("/designers");
          return;
        }
      });
    },
  },
};
</script>
